<template>
  <div class="reset-password-page">
    <div class="form-reset" v-if="!isResetPasswordSuccess">
      <form @submit.prevent="submit">
        <h1 class="title">パスワード設定</h1>
        <div class="item">
          <InputComponent
            type="text"
            :eyeIcon="true"
            :value="newPassword"
            placeholder="新しいパスワード"
            @handleChange="newPassword = $event"
          />
        </div>

        <div class="validate-password">
          <p :class="['validate-item', { 'active-valid': minLength }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            ８文字以上を含む
          </p>
          <p :class="['validate-item', { 'active-valid': validCharacters }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            英大文字と英子文字を含む
          </p>
          <p :class="['validate-item', { 'active-valid': specialCharacters }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            記号(!#$%&'()*+,-./:;&lt;=&gt;?@[]^_`{|}~）")を含む
          </p>
          <p :class="['validate-item', { 'active-valid': includeNumber }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            数字を含む
          </p>
        </div>

        <div class="item">
          <InputComponent
            type="text"
            :eyeIcon="true"
            :value="confirmPassword"
            placeholder="新しいパスワード(確認用)"
            @handleChange="confirmPassword = $event"
          />
        </div>

        <div class="btn-reset">
          <button
            :class="['btn fs-16 border-8', { disabled: disabledBtnReset }]"
            :disabled="disabledBtnReset"
            @click="handleResetPassword"
          >
            パスワードを設定する
          </button>
        </div>
      </form>
    </div>

    <div class="reset-success" v-if="isResetPasswordSuccess">
      <img :src="require(`@/assets/img/lock.png`)" />
      <h1 class="title">パスワードを設定いたしました。</h1>
      <div class="btn-reset">
        <button
          class="btn fs-16 border-8"
          @click="$router.push({ name: 'login' })"
        >
          ログインへ
        </button>
      </div>
    </div>

    <modal-component :modal-data="modalData" ref="modalComponent" />
  </div>
</template>

<script>
import InputComponent from "@/components/InputComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import {
  MIN_LENGTH_PASSWORD,
  REGEX_UPPERCASE_LOWERCASE,
  REGEX_ONE_NUMBER,
  REGEX_ONE_SPECIAL_CHAR,
  MESSAGE_FORBIDDEN_PASSWORD,
  HTTP_RESPONSE_STATUS_CODES
} from "@/common/constants.js";
import AuthService from "@/services/auth.service.js";

export default {
  name: "ResetPassword",

  components: {
    InputComponent,
    ModalComponent,
  },

  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      idAdmin: this.$route.query.idAdmin,
      token: this.$route.query.token || this.$route.query["amp;token"],
      modalData: null,
      isResetPasswordSuccess: false,
    };
  },

  created() {
    if (!this.idAdmin || !this.token) {
      this.$router.push({ name: "login" });
    }
  },

  computed: {
    minLength: function () {
      return this.newPassword && this.newPassword.length >= MIN_LENGTH_PASSWORD;
    },

    validCharacters: function () {
      return (
        this.newPassword && this.newPassword.match(REGEX_UPPERCASE_LOWERCASE)
      );
    },

    specialCharacters: function () {
      return this.newPassword && this.newPassword.match(REGEX_ONE_SPECIAL_CHAR);
    },

    includeNumber: function () {
      return this.newPassword && this.newPassword.match(REGEX_ONE_NUMBER);
    },

    disabledBtnReset: function () {
      if (!this.newPassword || !this.confirmPassword) {
        return true;
      }
      if (this.newPassword !== this.confirmPassword) {
        return true;
      }
      if (
        !this.minLength ||
        !this.validCharacters ||
        !this.specialCharacters ||
        !this.includeNumber
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    handleResetPassword() {
      AuthService.resetPassword(this.newPassword, this.token)
        .then(() => {
          this.isResetPasswordSuccess = true;
        })
        .catch((err) => {
          if (
            this.$store.state.errorStatus === 
              HTTP_RESPONSE_STATUS_CODES.STATUS_400_BAD_REQUEST &&
            err.message.content === MESSAGE_FORBIDDEN_PASSWORD
          ) {
            this.newPassword = null;
            this.confirmPassword = null;
          }
          this.modalData = err;
          this.$refs.modalComponent.open();
        });
    },
  },
};
</script>
