export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const FRONT_BASE_URL = process.env.VUE_APP_FRONT_URL;
export const FILE_BASE_URL = process.env.VUE_APP_FILE_URL;

export const HTTP_RESPONSE_STATUS_CODES = {
  STATUS_200_OK: 200,
  STATUS_400_BAD_REQUEST: 400,
  STATUS_404_NOT_FOUND: 404,
  STATUS_401_UNAUTHORIZED: 401,
  STATUS_403_FORBIDDEN: 403,
  STATUS_500_SERVER: 500,
};

export const MESSAGE_INCORRECT_PASSWORD =
  "正しい現在のパスワードを入力してください";

export const MESSAGE_FORBIDDEN_PASSWORD =
  "入力したパスワードが直近で利用した3つのパスワードに該当しますので、設定できませんでした。\n新しいパスワードを再度設定してください。";

export const STAFF_DATA_NOT_FOUND =
  "スタッフのデータが見つかりません";

export const STAFF_HAS_NO_PERSON_IN_CHARGE =
  "担当者紐付けがない";

export const MESSAGE = {
  MESSAGE_INSERT_SUCCESS: "登録完了！",
  MESSAGE_DELETE_FAILURE: "削除できません",
  MESSAGE_TOKEN_EXPIRED: "セッションが切れました。再ログインしてください。",
  MESSAGE_IP_ADDRESS_IS_NOT_ALLOWED: `現在の場所からはアクセスができません。
  所定のIPアドレスから再度ログインをしてください。`,
  MESSAGE_ACCOUNT_LOCKED: `このアカウントは現在ロックされています。
  「パスワードをお忘れの方はこちら」よりパスワードを再設定してください。
  再設定完了後にロック解除されます`,
  MESSAGE_INVALID_ACCOUNT: "登録メールアドレスまたはパスワードが間違っています",
};

export const MESSAGE_ENG_SUB = {
  MESSAGE_IP_ADDRESS_IS_NOT_ALLOWED: "IP address is not allowed",
  MESSAGE_ACCOUNT_LOCKED: "The account has been locked",
  MESSAGE_INVALID_ACCOUNT: "Invalid username or password",
};

export const AUTHORITY = {
  MASTER: 0, // admin
  GENERAL: 1, // user
  ADMIN: 2, // manager
  EDITOR: 3, // editor
};

export const AUTHORITY_TXT = {
  0: "アドミン",
  1: "ユーザー（閲覧のみ）",
  2: "マネージャー",
  3: "ユーザー（編集可）",
};

export const APPROVAL_STATUS_FILTER = {
  NOT_YET_SUBMITTED: "Not yet submitted",
  REQUESTED: "Requested",
  CANCELLED: "Cancelled",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  APPROVAL_COMPLETE: "Approval complete",
};

export const APPROVAL_STATUS = {
  APPROVED_FINAL: "approved_final",
  APPROVED: "approved",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  REQUESTED: "requested",
  NOT_REQUESTED: "not_requested",
  TO_BE_REQUESTED: "to_be_requested",
  CONTINUE_REQUEST: "continue_request",
  APPROVAL_NEEDED: "approval_needed",
};

export const APPROVAL_STATUS_TXT = {
  APPROVED_FINAL: "承認完了",
  APPROVED: "承認",
  REJECTED: "差戻",
  CANCELLED: "キャンセル",
  REQUESTED: "結果待ち",
  NOT_REQUESTED: "未申請",
};

export const LEVEL_PLAN = {
  FREE: 0,
  BASIC: 1,
  BUSINESS: 2,
  ENTERPRISE: 3,
};

export const ALERT_TYPE = {
  ALERT_WARNING: 1,
  ALERT_ATTENTION_NEEDED: 2,
  ALERT_UPDATE_NEEDED: 3,
  ALERT_MISSING_INFORMATION: 4,
};

export const ALERT = {
  ALERT_SPECIFICATION_EXPIRED: "expired",
  ALERT_INAPPROPRIATE_VISA_TYPE: "Inappropriate Visa Type",
  ALERT_INVALID_RESIDENCE_CARD: "Invalid Residence Card",
  ALERT_MISSING_RELATED_DOCUMENT: "Missing Related Document",
  ALERT_MISSING_RELATED_DOCUMENT_TWO: "Missing Related Document 2",
  NEED_TO_CONFIRM_EMPLOYMENT_RESTRICTION:
    "Need to confirm employment restriction",
  WITHIN_THREE_MONTHS: "Within 3 months",
  WITHIN_TWO_MONTHS: "Within 2 months",
  WITHIN_ONE_MONTHS: "Within 1 months",
  WITHIN_TWO_WEEKS: "Within 2 weeks",
  WITHIN_ONE_WEEKS: "Within 1 week",
  WITHIN_THREE_MONTHS_EXTENDED: "Within 3 months (Extended)",
  WITHIN_TWO_MONTHS_EXTENDED: "Within 2 months (Extended)",
  WITHIN_ONE_MONTHS_EXTENDED: "Within 1 months (Extended)",
  WITHIN_TWO_WEEKS_EXTENDED: "Within 2 weeks (Extended)",
  WITHIN_ONE_WEEKS_EXTENDED: "Within 1 week (Extended)",
  ALERT_PAY_ATTENTION: "Pay Attention",
  ALERT_TBD: "TBD",
  ALERT_NOT_ASSIGNED: "Not assigned",
  ALERT_NEED_TO_CONFIRM_EMPLOYMENT_RESTRICTION:
    "Need to confirm employment restriction",
  ALERT_WARNING_TEXT: "Warning",
  ALERT_ATTENTION_NEEDED_TEXT: "Attention Needed",
  ALERT_UPDATE_NEEDED_TEXT: "Update Needed",
  ALERT_MISSING_INFORMATION_TEXT: "Missing Information",
  ALERT_SAVED_TEMPORARY: "Saved temporary",
  ALERT_HAS_REQUEST: "Has request",
  ALERT_NONE_CHECK: "None check",
  ALERT_NONE_TEXT: "None Alert",
};

export const COLOR_ALERT = {
  WARNING: "#f50057",
  MISSING_INFORMATION: "#f50057",
  ATTENTION_NEEDED: "#f5b629",
  UPDATE_NEEDED: "#1565c0",
};

export const REG_STATUS = {
  NEW: "new",
  COMPLETE: "complete",
  UPDATE: "update",
};

export const REQUIRED_ITEM = {
  REQUIRED: "Required",
  OPTIONAL: "Optional",
  HIDDEN: "Hidden",
};

export const CARD_CHECK = {
  NOT_REPORTED: "Not Reported",
  REPORTED_FAKE: "Reported Fake",
  UNCONFIRMED: "Unconfirmed",
};

export const REPORTED_LIST = [
  { name: "対応済み", value: true },
  { name: "対応待ち", value: false },
];

export const REGTYPE = {
  NEW: "New",
  RENEW: "Renew",
};

export const DATA_INPUT_STATUS = {
  PENDING: "Pending",
  FINISHED: "Finished",
};

export const DATA_INPUT_STATUS_LIST = [
  { name: "入力待ち", value: "Pending" },
  { name: "入力済", value: "Finished" },
];

export const CURRENT_STATUS = {
  WORKING: "Working",
  HIRED: "Hired",
  RESIGNED: "Resigned",
  SUSPENDED: "Suspended",
  STATUS_UPDATE_NEEDED: "Status update needed",
  REJECT_OFFER: "Reject Offer",
};

export const CURRENT_STATUS_LIST = [
  { name: "入社", value: "Working" },
  { name: "入社待ち", value: "Hired" },
  { name: "退職", value: "Resigned" },
  { name: "停職", value: "Suspended" },
  { name: "要変更", value: "Status update needed" },
  { name: "入社辞退", value: "Reject Offer" },
];

export const REPORTED = {
  NOT_CHECK: "Not Check",
  NOT_REPORTED: "Not Reported",
  REPORTED_FAKE: "Reported Fake",
};

export const DATA_UPDATE_STATUS = {
  NOT_YET_UPDATED: "Not yet updated",
  WAITING_FOR_APPROVAL: "Waiting for Approval",
  RE_UPLOAD_NEEDED: "Re-upload needed",
  APPLYING: "Applying",
  NORMAL: "Normal",
};

export const UPDATE_STATUS = {
  APPLYING: "Applying",
  UPDATED: "Updated",
};

export const UPDATE_STAGE = {
  APPLYING: "Applying",
  UPDATED: "Updated",
};

export const UPDATE_STAGE_UPDATED = [{ name: "更新済", value: "Updated" }];

export const UPDATE_STAGE_LIST = [
  { name: "在留期間更新許可申請中", value: "Applying" },
  { name: "未申請", value: "" },
];

export const INSURANCE_LIST = [
  { name: "加入", value: "Target" },
  { name: "未加入", value: "Non-target" },
  { name: "未確認", value: "Unconfirmed" },
  { name: "不明", value: "Unknown" },
];

export const STAFF_TYPE = {
  REGULAR: "Regular",
  CONTRACT: "Contract",
  PART: "Part",
  ARBEIT: "Arbeit",
  TEMPORARY: "Temporary",
  OTHERS: "Others",
};

export const STAFF_TYPE_LIST = [
  { name: "正社員", value: "Regular" },
  { name: "契約社員", value: "Contract" },
  { name: "パート", value: "Part" },
  { name: "アルバイト", value: "Arbeit" },
  { name: "派遣社員", value: "Temporary" },
  { name: "その他", value: "Others" },
];

export const MECHANISM_TYPE = {
  UPDATING: "Updating",
  APPLYING: "Applying",
};

export const VISA_TYPE_LIST = [
  { name: "留学", value: "Student" },
  { name: "家族滞在", value: "Dependent" },
  { name: "特定活動", value: "Designated Activities" },
  { name: "永住者", value: "Permanent" },
  { name: "日本人の配偶者等", value: "Spouse or Child of Japanese National" },
  { name: "永住者の配偶者等", value: "Spouse or Child of Permanent Resident" },
  { name: "定住者", value: "Long Term" },
  { name: "外交", value: "Diplomat" },
  { name: "公用", value: "Official" },
  { name: "教授", value: "Professor" },
  { name: "芸術", value: "Artist" },
  { name: "宗教", value: "Religious Activities" },
  { name: "報道", value: "Journalist" },
  { name: "高度専門職1号", value: "Highly Skilled Professional (I)" },
  { name: "高度専門職2号", value: "Highly Skilled Professional (II)" },
  { name: "経営・管理", value: "Business Manager" },
  { name: "法律・会計業務", value: "Legal/Accounting Services" },
  { name: "医療", value: "Medical Services" },
  { name: "研究", value: "Researcher" },
  { name: "教育", value: "Instructor" },
  {
    name: "技術・人文知識・国際業務",
    value: "Engineer/Specialist in Humanities/International Services",
  },
  { name: "企業内転勤", value: "Intra-Company Transferee" },
  { name: "介護", value: "Care Worker" },
  { name: "興行", value: "Entertainer" },
  { name: "技能", value: "Skilled Labor" },
  { name: "技能実習", value: "Technical Intern Training" },
  { name: "文化活動", value: "Cultural Activities" },
  { name: "短期滞在", value: "Temporary Visitor" },
  { name: "研修", value: "Trainee" },
  { name: "特別永住者", value: "Special Permanent Resident" },
  { name: "特定技能1号", value: "Specified Skilled Worker (i)" },
  { name: "特定技能2号", value: "Specified Skilled Worker (ii)" },
];

export const VISA_TYPEMT_LIST = [
  { name: "留学", value: "Student" },
  { name: "家族滞在", value: "Dependent" },
  { name: "特定活動", value: "Designated Activities" },
  { name: "永住者", value: "Permanent" },
  { name: "日本人の配偶者等", value: "Spouse or Child of Japanese National" },
  { name: "永住者の配偶者等", value: "Spouse or Child of Permanent Resident" },
  { name: "定住者", value: "Long Term" },
  { name: "外交", value: "Diplomat" },
  { name: "公用", value: "Official" },
  { name: "教授", value: "Professor" },
  { name: "芸術", value: "Artist" },
  { name: "宗教", value: "Religious Activities" },
  { name: "報道", value: "Journalist" },
  { name: "高度専門職1号", value: "Highly Skilled Professional (I)" },
  { name: "高度専門職2号", value: "Highly Skilled Professional (II)" },
  { name: "経営・管理", value: "Business Manager" },
  { name: "法律・会計業務", value: "Legal/Accounting Services" },
  { name: "医療", value: "Medical Services" },
  { name: "研究", value: "Researcher" },
  { name: "教育", value: "Instructor" },
  {
    name: "技術・人文知識・国際業務",
    value: "Engineer/Specialist in Humanities/International Services",
  },
  { name: "企業内転勤", value: "Intra-Company Transferee" },
  { name: "介護", value: "Care Worker" },
  { name: "興行", value: "Entertainer" },
  { name: "技能", value: "Skilled Labor" },
  { name: "技能実習", value: "Technical Intern Tranining" },
  { name: "文化活動", value: "Cultural Activities" },
  { name: "短期滞在", value: "Temporary Visitor" },
  { name: "研修", value: "Trainee" },
  {
    name: "特定活動（ワーキングホリデー）",
    value: "Designated Activities (Working Holiday)",
  },
  { name: "特定活動（EPA）", value: "Designated Activities (EPA)" },
  {
    name: "特定活動（建設分野)",
    value: "Designated Activities (Construction)",
  },
  {
    name: "特定活動（造船分野）",
    value: "Designated Activities (Shipbuilding)",
  },
  { name: "特定活動（外国人調理師）", value: "Designated Activities (Chef)" },
  {
    name: "特定活動（ハラール牛肉生産）",
    value: "Designated Activities (Halal Beef)",
  },
  {
    name: "特定活動（製造分野）",
    value: "Designated Activities (Manufacturing)",
  },
  {
    name: "特定活動（家事支援）",
    value: "Designated Activities (Housekeeping)",
  },
  {
    name: "特定活動（就職活動）",
    value: "Designated Activities (Job-seeking)",
  },
  {
    name: "特定活動（本邦大卒者）",
    value: "Designated Activities (Graduate from a university in Japan)",
  },
  { name: "特定活動（農業）", value: "Designated Activities (Agriculture)" },
  {
    name: "特定活動（日系四世）",
    value: "Designated Activities (Fourth-generation Japanese)",
  },
  {
    name: "特定活動（高度学術研究活動）",
    value: "Designated Activities (Advanced academic research activities)",
  },
  {
    name: "特定活動（高度専門・技術活動）",
    value: "Designated Activities (Advanced specialized/technical activities)",
  },
  {
    name: "特定活動（高度経営・管理活動）",
    value: "Designated Activities (Advanced business management activities)",
  },
  {
    name: "特定活動（高度人材の就労配偶者）",
    value:
      "Designated Activities (Spouse of Highly Skilled Foreign Professional)",
  },
  { name: "特定活動（その他）", value: "Designated Activities (Others)" },
  { name: "特別永住者", value: "Special Permanent Resident" },
  { name: "特定技能1号", value: "Specified Skilled Worker (i)" },
  { name: "特定技能2号", value: "Specified Skilled Worker (ii)" },
  {
    name: "特定技能1号（介護）",
    value: "Specified Skilled Worker (i) (Care Worker)",
  },
  {
    name: "特定技能1号（ビルクリーニング）",
    value: "Specified Skilled Worker (i) (Building cleaning Management)",
  },
  {
    name: "特定技能1号（素形材産業）",
    value: "Specified Skilled Worker (i) (Machine Parts & Tooling Industries)",
  },
  {
    name: "特定技能1号（産業機械製造業）",
    value: "Specified Skilled Worker (i) (industrial machinery industry)",
  },
  {
    name: "特定技能1号（電気・電子情報関連産業）",
    value:
      "Specified Skilled Worker (i) (Electric, Electronics and Information Industries)",
  },
  {
    name: "特定技能1号（建設）",
    value: "Specified Skilled Worker (i) (Construction Industry)",
  },
  {
    name: "特定技能1号（造船・舶用工業）",
    value:
      "Specified Skilled Worker (i) (Shipbuilding and Ship Machinery Industry)",
  },
  {
    name: "特定技能1号（自動車整備）",
    value: "Specified Skilled Worker (i) (Automobile repair and maintenance)",
  },
  {
    name: "特定技能1号（航空）",
    value: "Specified Skilled Worker (i) (Aviation Industry)",
  },
  {
    name: "特定技能1号（宿泊）",
    value: "Specified Skilled Worker (i) (Accommodation Industry)",
  },
  {
    name: "特定技能1号（農業）",
    value: "Specified Skilled Worker (i) (Agriculture)",
  },
  {
    name: "特定技能1号（漁業）",
    value: "Specified Skilled Worker (i) (Fishery & Aquaculture)",
  },
  {
    name: "特定技能1号（飲食料品製造業）",
    value: "Specified Skilled Worker (i) (Manufacture of food and beverages)",
  },
  {
    name: "特定技能1号（外食業）",
    value: "Specified Skilled Worker (i) (Food service industry)",
  },
  {
    name: "特定技能2号（建設）",
    value: "Specified Skilled Worker (ii) (Construction Industry)",
  },
  {
    name: "特定技能2号（造船・舶用工業）",
    value:
      "Specified Skilled Worker (ii) (Shipbuilding and Ship Machinery Industry)",
  },
  {
    name: "不明",
    value: "Others",
  },
];

export const CSV_STAFF_JP_DICT = {
  id_staff: "従業員ID",
  staffid: "社員番号",
  attendanceid: "勤怠ID",
  name_staff: "従業員氏名",
  name_staff_register: "従業員氏名(カタカナ)",
  shop_name: "所属店舗",
  shopid: "店舗番号",
  employment_insurance: "雇用保険加入状況",
  social_insurance: "社会保険加入状況",
  current_status: "ステータス",
  data_input_status: "データ入力状況",
  registration_date: " 登録日",
  submission_date: "更新日",
  working_date_staff: "入社日",
  resignation_date: "退職日",
  phone_number: "電話番号",
  email_staff: "メールアドレス",
  residence_card_number: "在留カード番号",
  gender_staff: "性別",
  nationality: "国籍",
  birthday: "生年月日",
  address_staff: "居住地",
  post_code: "郵便番号",
  visa_type: "在留資格",
  working_time_limit: "28時間制限",
  working_permission: "資格外活動許可",
  expired_date: "在留期限",
  visa_status: "失効情報照会結果",
  alerts: "アラート",
  confirmation_date: "失効情報照会結果画像アップロード日",
  first_expiration_inquiry_date: "失効情報照会実施日（初回)",
  recent_expiration_inquiry_date: "失効情報照会実施日（最新）",
  saved_temporarily: "一時保存フラグ",
  visa_typemt: "在留資格",
  data_update_status: "更新ステータス",
  extend_expired_date: "延長期限",
  update_stage: "更新種類",
  start_working_date: "入社日",
  job_qualification: "就労資格確認",
  name_company: "所属企業",
  resigned_date: "退職日",
  memo: "メモ",
  graduation_date: "卒業日",
  student_card_expired_date: "学生証期限",
  name_shop: "所属店舗",
  staff_type: "社員区分",
  read_date: "読取り日",
  "-----": "空白(スペース)",
};

export const NATIONALITIE_LIST = [
  {
    nationality: "Iceland",
    nationality_jp: "アイスランド",
    nationality_jp2: "アイスランド",
    group: "",
  },
  {
    nationality: "Ireland",
    nationality_jp: "アイルランド",
    nationality_jp2: "アイルランド",
    group: "",
  },
  {
    nationality: "Azerbaijan",
    nationality_jp: "アゼルバイジャン",
    nationality_jp2: "アゼルバイジャン",
    group: "",
  },
  {
    nationality: "Afghanistan",
    nationality_jp: "アフガニスタン",
    nationality_jp2: "アフガニスタン",
    group: "",
  },
  {
    nationality: "America",
    nationality_jp: "米国",
    nationality_jp2: "アメリカ",
    group: "",
  },
  {
    nationality: "United Arab Emirates",
    nationality_jp: "アラブ首長国連邦",
    nationality_jp2: "アラブシュチョウコクレンポウ",
    group: "",
  },
  {
    nationality: "Algeria",
    nationality_jp: "アルジェリア",
    nationality_jp2: "アルジェリア",
    group: "",
  },
  {
    nationality: "Argentina",
    nationality_jp: "アルゼンチン",
    nationality_jp2: "アルゼンチン",
    group: "",
  },
  {
    nationality: "Albania",
    nationality_jp: "アルバニア",
    nationality_jp2: "アルバニア",
    group: "",
  },
  {
    nationality: "Armenia",
    nationality_jp: "アルメニア",
    nationality_jp2: "アルメニア",
    group: "",
  },
  {
    nationality: "Angola",
    nationality_jp: "アンゴラ",
    nationality_jp2: "アンゴラ",
    group: "",
  },
  {
    nationality: "Antigua And Barbuda",
    nationality_jp: "アンティグア・バーブーダ",
    nationality_jp2: "アンティグア・バーブーダ",
    group: "",
  },
  {
    nationality: "Andorra",
    nationality_jp: "アンドラ",
    nationality_jp2: "アンドラ",
    group: "",
  },
  {
    nationality: "Yemen",
    nationality_jp: "イエメン",
    nationality_jp2: "イエメン",
    group: "",
  },
  {
    nationality: "England",
    nationality_jp: "英国",
    nationality_jp2: "イギリス",
    group: "",
  },
  {
    nationality: "Israel",
    nationality_jp: "イスラエル",
    nationality_jp2: "イスラエル",
    group: "",
  },
  {
    nationality: "Italy",
    nationality_jp: "イタリア",
    nationality_jp2: "イタリア",
    group: "",
  },
  {
    nationality: "Iraq",
    nationality_jp: "イラク",
    nationality_jp2: "イラク",
    group: "",
  },
  {
    nationality: "Iran",
    nationality_jp: "イラン",
    nationality_jp2: "イラン",
    group: "",
  },
  {
    nationality: "India",
    nationality_jp: "インド",
    nationality_jp2: "インド",
    group: "",
  },
  {
    nationality: "Indonesia",
    nationality_jp: "インドネシア",
    nationality_jp2: "インドネシア",
    group: "",
  },
  {
    nationality: "Uganda",
    nationality_jp: "ウガンダ",
    nationality_jp2: "ウガンダ",
    group: "",
  },
  {
    nationality: "Ukraine",
    nationality_jp: "ウクライナ",
    nationality_jp2: "ウクライナ",
    group: "",
  },
  {
    nationality: "Uzbekistan",
    nationality_jp: "ウズベキスタン",
    nationality_jp2: "ウズベキスタン",
    group: "",
  },
  {
    nationality: "Uruguay",
    nationality_jp: "ウルグアイ",
    nationality_jp2: "ウルグアイ",
    group: "",
  },
  {
    nationality: "Ecuador",
    nationality_jp: "エクアドル",
    nationality_jp2: "エクアドル",
    group: "",
  },
  {
    nationality: "Egypt",
    nationality_jp: "エジプト",
    nationality_jp2: "エジプト",
    group: "",
  },
  {
    nationality: "Estonia",
    nationality_jp: "エストニア",
    nationality_jp2: "エストニア",
    group: "",
  },
  {
    nationality: "Ethiopia",
    nationality_jp: "エチオピア",
    nationality_jp2: "エチオピア",
    group: "",
  },
  {
    nationality: "Eritrea",
    nationality_jp: "エリトリア",
    nationality_jp2: "エリトリア",
    group: "",
  },
  {
    nationality: "El Salvador",
    nationality_jp: "エルサルバドル",
    nationality_jp2: "エルサルバドル",
    group: "",
  },
  {
    nationality: "Australia",
    nationality_jp: "オーストラリア",
    nationality_jp2: "オーストラリア",
    group: "",
  },
  {
    nationality: "Austria",
    nationality_jp: "オーストリア",
    nationality_jp2: "オーストリア",
    group: "",
  },
  {
    nationality: "Oman",
    nationality_jp: "オマーン",
    nationality_jp2: "オマーン",
    group: "",
  },
  {
    nationality: "Netherlands",
    nationality_jp: "オランダ",
    nationality_jp2: "オランダ",
    group: "",
  },
  {
    nationality: "Ghana",
    nationality_jp: "ガーナ",
    nationality_jp2: "ガーナ",
    group: "",
  },
  {
    nationality: "Cape Verde",
    nationality_jp: "カーボヴェルデ",
    nationality_jp2: "カーボヴェルデ",
    group: "",
  },
  {
    nationality: "Guyana",
    nationality_jp: "ガイアナ",
    nationality_jp2: "ガイアナ",
    group: "",
  },
  {
    nationality: "Kazakhstan",
    nationality_jp: "カザフスタン",
    nationality_jp2: "カザフスタン",
    group: "",
  },
  {
    nationality: "Qatar",
    nationality_jp: "カタール",
    nationality_jp2: "カタール",
    group: "",
  },
  {
    nationality: "Canada",
    nationality_jp: "カナダ",
    nationality_jp2: "カナダ",
    group: "",
  },
  {
    nationality: "Gabon",
    nationality_jp: "ガボン",
    nationality_jp2: "ガボン",
    group: "",
  },
  {
    nationality: "Cameroon",
    nationality_jp: "カメルーン",
    nationality_jp2: "カメルーン",
    group: "",
  },
  {
    nationality: "Korea",
    nationality_jp: "韓国",
    nationality_jp2: "カンコク",
    group: "",
  },
  {
    nationality: "Gambia",
    nationality_jp: "ガンビア",
    nationality_jp2: "ガンビア",
    group: "",
  },
  {
    nationality: "Cambodia",
    nationality_jp: "カンボジア",
    nationality_jp2: "カンボジア",
    group: "",
  },
  {
    nationality: "Guinea",
    nationality_jp: "ギニア",
    nationality_jp2: "ギニア",
    group: "",
  },
  {
    nationality: "Guinea Bissau",
    nationality_jp: "ギニアビサウ",
    nationality_jp2: "ギニアビサウ",
    group: "",
  },
  {
    nationality: "Cyprus",
    nationality_jp: "キプロス",
    nationality_jp2: "キプロス",
    group: "",
  },
  {
    nationality: "Cuba",
    nationality_jp: "キューバ",
    nationality_jp2: "キューバ",
    group: "",
  },
  {
    nationality: "Greece",
    nationality_jp: "ギリシャ",
    nationality_jp2: "ギリシャ",
    group: "",
  },
  {
    nationality: "Kiribati",
    nationality_jp: "キリバス",
    nationality_jp2: "キリバス",
    group: "",
  },
  {
    nationality: "Kyrgyzstan",
    nationality_jp: "キルギス",
    nationality_jp2: "キルギス",
    group: "",
  },
  {
    nationality: "Guatemala",
    nationality_jp: "グアテマラ",
    nationality_jp2: "グアテマラ",
    group: "",
  },
  {
    nationality: "Kuwait",
    nationality_jp: "クウェート",
    nationality_jp2: "クウェート",
    group: "",
  },
  {
    nationality: "Georgia",
    nationality_jp: "グルジア",
    nationality_jp2: "グルジア",
    group: "",
  },
  {
    nationality: "Grenada",
    nationality_jp: "グレナダ",
    nationality_jp2: "グレナダ",
    group: "",
  },
  {
    nationality: "Croatia",
    nationality_jp: "クロアチア",
    nationality_jp2: "クロアチア",
    group: "",
  },
  {
    nationality: "Kenya",
    nationality_jp: "ケニア",
    nationality_jp2: "ケニア",
    group: "",
  },
  {
    nationality: "Cote D'Ivoire",
    nationality_jp: "コートジボワール",
    nationality_jp2: "コートジボワール",
    group: "",
  },
  {
    nationality: "Costa Rica",
    nationality_jp: "コスタリカ",
    nationality_jp2: "コスタリカ",
    group: "",
  },
  {
    nationality: "Kosovo",
    nationality_jp: "コソボ",
    nationality_jp2: "コソボ",
    group: "",
  },
  {
    nationality: "Comoros",
    nationality_jp: "コモロ",
    nationality_jp2: "コモロ",
    group: "",
  },
  {
    nationality: "Columbia",
    nationality_jp: "コロンビア",
    nationality_jp2: "コロンビア",
    group: "",
  },
  {
    nationality: "Republic of the Congo",
    nationality_jp: "コンゴ共和国",
    nationality_jp2: "コンゴキョウワコク",
    group: "",
  },
  {
    nationality: "Democratic Republic Of The Congo",
    nationality_jp: "コンゴ民主共和国",
    nationality_jp2: "コンゴミンシュキョウワコク",
    group: "",
  },
  {
    nationality: "Saudi Arabia",
    nationality_jp: "サウジアラビア",
    nationality_jp2: "サウジアラビア",
    group: "",
  },
  {
    nationality: "Samoa",
    nationality_jp: "サモア",
    nationality_jp2: "サモア",
    group: "",
  },
  {
    nationality: "Sao Tome And Principe",
    nationality_jp: "サントメ・プリンシペ",
    nationality_jp2: "サントメ・プリンシペ",
    group: "",
  },
  {
    nationality: "Zambia",
    nationality_jp: "ザンビア",
    nationality_jp2: "ザンビア",
    group: "",
  },
  {
    nationality: "San Marino",
    nationality_jp: "サンマリノ",
    nationality_jp2: "サンマリノ",
    group: "",
  },
  {
    nationality: "Sierra Leone",
    nationality_jp: "シエラレオネ",
    nationality_jp2: "シエラレオネ",
    group: "",
  },
  {
    nationality: "Djibouti",
    nationality_jp: "ジブチ",
    nationality_jp2: "ジブチ",
    group: "",
  },
  {
    nationality: "Jamaica",
    nationality_jp: "ジャマイカ",
    nationality_jp2: "ジャマイカ",
    group: "",
  },
  {
    nationality: "Syria",
    nationality_jp: "シリア",
    nationality_jp2: "シリア",
    group: "",
  },
  {
    nationality: "Singapore",
    nationality_jp: "シンガポール",
    nationality_jp2: "シンガポール",
    group: "",
  },
  {
    nationality: "Zimbabwe",
    nationality_jp: "ジンバブエ",
    nationality_jp2: "ジンバブエ",
    group: "",
  },
  {
    nationality: "Swiss",
    nationality_jp: "スイス",
    nationality_jp2: "スイス",
    group: "",
  },
  {
    nationality: "Sweden",
    nationality_jp: "スウェーデン",
    nationality_jp2: "スウェーデン",
    group: "",
  },
  {
    nationality: "Sudan",
    nationality_jp: "スーダン",
    nationality_jp2: "スーダン",
    group: "",
  },
  {
    nationality: "Spain",
    nationality_jp: "スペイン",
    nationality_jp2: "スペイン",
    group: "",
  },
  {
    nationality: "Suriname",
    nationality_jp: "スリナム",
    nationality_jp2: "スリナム",
    group: "",
  },
  {
    nationality: "Sri Lanka",
    nationality_jp: "スリランカ",
    nationality_jp2: "スリランカ",
    group: "",
  },
  {
    nationality: "Slovakia",
    nationality_jp: "スロバキア",
    nationality_jp2: "スロバキア",
    group: "",
  },
  {
    nationality: "Slovenia",
    nationality_jp: "スロベニア",
    nationality_jp2: "スロベニア",
    group: "",
  },
  {
    nationality: "Swaziland",
    nationality_jp: "スワジランド",
    nationality_jp2: "スワジランド",
    group: "",
  },
  {
    nationality: "Seychelles",
    nationality_jp: "セーシェル",
    nationality_jp2: "セーシェル",
    group: "",
  },
  {
    nationality: "Equatorial Guinea",
    nationality_jp: "赤道ギニア",
    nationality_jp2: "セキドウギニア",
    group: "",
  },
  {
    nationality: "Senegal",
    nationality_jp: "セネガル",
    nationality_jp2: "セネガル",
    group: "",
  },
  {
    nationality: "Serbia",
    nationality_jp: "セルビア",
    nationality_jp2: "セルビア",
    group: "",
  },
  {
    nationality: "Serbia And Montenegro",
    nationality_jp: "セルビア・モンテネグロ",
    nationality_jp2: "セルビア・モンテネグロ",
    group: "",
  },
  {
    nationality: "Saint Kitts And Nevis",
    nationality_jp: "セントクリストファー・ネーヴィス",
    nationality_jp2: "セントクリストファー・ネーヴィス",
    group: "",
  },
  {
    nationality: "St. Vincent",
    nationality_jp: "セントビンセント",
    nationality_jp2: "セントビンセント",
    group: "",
  },
  {
    nationality: "Saint Lucia",
    nationality_jp: "セントルシア",
    nationality_jp2: "セントルシア",
    group: "",
  },
  {
    nationality: "Somalia",
    nationality_jp: "ソマリア",
    nationality_jp2: "ソマリア",
    group: "",
  },
  {
    nationality: "Solomon",
    nationality_jp: "ソロモン",
    nationality_jp2: "ソロモン",
    group: "",
  },
  {
    nationality: "Thai",
    nationality_jp: "タイ",
    nationality_jp2: "タイ",
    group: "",
  },
  {
    nationality: "Taiwan",
    nationality_jp: "台湾",
    nationality_jp2: "タイワン",
    group: "",
  },
  {
    nationality: "Tajikistan",
    nationality_jp: "タジキスタン",
    nationality_jp2: "タジキスタン",
    group: "",
  },
  {
    nationality: "Tanzania",
    nationality_jp: "タンザニア",
    nationality_jp2: "タンザニア",
    group: "",
  },
  {
    nationality: "Czech Republic",
    nationality_jp: "チェコ",
    nationality_jp2: "チェコ",
    group: "",
  },
  {
    nationality: "Chad",
    nationality_jp: "チャド",
    nationality_jp2: "チャド",
    group: "",
  },
  {
    nationality: "Central African Republic",
    nationality_jp: "中央アフリカ",
    nationality_jp2: "チュウオウアフリカ",
    group: "",
  },
  {
    nationality: "China",
    nationality_jp: "中国",
    nationality_jp2: "チュウゴク",
    group: "",
  },
  {
    nationality: "Tunisia",
    nationality_jp: "チュニジア",
    nationality_jp2: "チュニジア",
    group: "",
  },
  {
    nationality: "North Korea",
    nationality_jp: "朝鮮",
    nationality_jp2: "チョウセン",
    group: "",
  },
  {
    nationality: "Chile",
    nationality_jp: "チリ",
    nationality_jp2: "チリ",
    group: "",
  },
  {
    nationality: "Tuvalu",
    nationality_jp: "ツバル",
    nationality_jp2: "ツバル",
    group: "",
  },
  {
    nationality: "Denmark",
    nationality_jp: "デンマーク",
    nationality_jp2: "デンマーク",
    group: "",
  },
  {
    nationality: "Germany",
    nationality_jp: "ドイツ",
    nationality_jp2: "ドイツ",
    group: "",
  },
  {
    nationality: "Togo",
    nationality_jp: "トーゴ",
    nationality_jp2: "トーゴ",
    group: "",
  },
  {
    nationality: "Dominican",
    nationality_jp: "ドミニカ",
    nationality_jp2: "ドミニカ",
    group: "",
  },
  {
    nationality: "Dominican Republic",
    nationality_jp: "ドミニカ共和国",
    nationality_jp2: "ドミニカキョウワコク",
    group: "",
  },
  {
    nationality: "Republic Of Trinidad And Tobago",
    nationality_jp: "トリニダード・トバゴ",
    nationality_jp2: "トリニダード・トバゴ",
    group: "",
  },
  {
    nationality: "Turkmenistan",
    nationality_jp: "トルクメニスタン",
    nationality_jp2: "トルクメニスタン",
    group: "",
  },
  {
    nationality: "Turkey",
    nationality_jp: "トルコ",
    nationality_jp2: "トルコ",
    group: "",
  },
  {
    nationality: "Tonga",
    nationality_jp: "トンガ",
    nationality_jp2: "トンガ",
    group: "",
  },
  {
    nationality: "Nigeria",
    nationality_jp: "ナイジェリア",
    nationality_jp2: "ナイジェリア",
    group: "",
  },
  {
    nationality: "Nauru",
    nationality_jp: "ナウル",
    nationality_jp2: "ナウル",
    group: "",
  },
  {
    nationality: "Namibia",
    nationality_jp: "ナミビア",
    nationality_jp2: "ナミビア",
    group: "",
  },
  {
    nationality: "Nicaragua",
    nationality_jp: "ニカラグア",
    nationality_jp2: "ニカラグア",
    group: "",
  },
  {
    nationality: "Niger",
    nationality_jp: "ニジェール",
    nationality_jp2: "ニジェール",
    group: "",
  },
  {
    nationality: "New Zealand",
    nationality_jp: "ニュージーランド",
    nationality_jp2: "ニュージーランド",
    group: "",
  },
  {
    nationality: "Nepal",
    nationality_jp: "ネパール",
    nationality_jp2: "ネパール",
    group: "",
  },
  {
    nationality: "Norway",
    nationality_jp: "ノルウェー",
    nationality_jp2: "ノルウェー",
    group: "",
  },
  {
    nationality: "Bahrain",
    nationality_jp: "バーレーン",
    nationality_jp2: "バーレーン",
    group: "",
  },
  {
    nationality: "Haiti",
    nationality_jp: "ハイチ",
    nationality_jp2: "ハイチ",
    group: "",
  },
  {
    nationality: "Pakistan",
    nationality_jp: "パキスタン",
    nationality_jp2: "パキスタン",
    group: "",
  },
  {
    nationality: "Vatican",
    nationality_jp: "バチカン",
    nationality_jp2: "バチカン",
    group: "",
  },
  {
    nationality: "Panama",
    nationality_jp: "パナマ",
    nationality_jp2: "パナマ",
    group: "",
  },
  {
    nationality: "Vanuatu",
    nationality_jp: "バヌアツ",
    nationality_jp2: "バヌアツ",
    group: "",
  },
  {
    nationality: "Bahamas",
    nationality_jp: "バハマ",
    nationality_jp2: "バハマ",
    group: "",
  },
  {
    nationality: "Papua New Guinea",
    nationality_jp: "パプアニューギニア",
    nationality_jp2: "パプアニューギニア",
    group: "",
  },
  {
    nationality: "Palau",
    nationality_jp: "パラオ",
    nationality_jp2: "パラオ",
    group: "",
  },
  {
    nationality: "Paraguay",
    nationality_jp: "パラグアイ",
    nationality_jp2: "パラグアイ",
    group: "",
  },
  {
    nationality: "Barbados",
    nationality_jp: "バルバドス",
    nationality_jp2: "バルバドス",
    group: "",
  },
  {
    nationality: "Palestine",
    nationality_jp: "パレスチナ",
    nationality_jp2: "パレスチナ",
    group: "",
  },
  {
    nationality: "Hungary",
    nationality_jp: "ハンガリー",
    nationality_jp2: "ハンガリー",
    group: "",
  },
  {
    nationality: "Bangladesh",
    nationality_jp: "バングラデシュ",
    nationality_jp2: "バングラデシュ",
    group: "",
  },
  {
    nationality: "East Timor",
    nationality_jp: "東ティモール",
    nationality_jp2: "ヒガシティモール",
    group: "",
  },
  {
    nationality: "Fiji",
    nationality_jp: "フィジー",
    nationality_jp2: "フィジー",
    group: "",
  },
  {
    nationality: "Philippines",
    nationality_jp: "フィリピン",
    nationality_jp2: "フィリピン",
    group: "",
  },
  {
    nationality: "Finland",
    nationality_jp: "フィンランド",
    nationality_jp2: "フィンランド",
    group: "",
  },
  {
    nationality: "Bhutan",
    nationality_jp: "ブータン",
    nationality_jp2: "ブータン",
    group: "",
  },
  {
    nationality: "Brazil",
    nationality_jp: "ブラジル",
    nationality_jp2: "ブラジル",
    group: "",
  },
  {
    nationality: "France",
    nationality_jp: "フランス",
    nationality_jp2: "フランス",
    group: "",
  },
  {
    nationality: "Bulgaria",
    nationality_jp: "ブルガリア",
    nationality_jp2: "ブルガリア",
    group: "",
  },
  {
    nationality: "Burkina Faso",
    nationality_jp: "ブルキナファソ",
    nationality_jp2: "ブルキナファソ",
    group: "",
  },
  {
    nationality: "Burnei",
    nationality_jp: "ブルネイ",
    nationality_jp2: "ブルネイ",
    group: "",
  },
  {
    nationality: "Burundi",
    nationality_jp: "ブルンジ",
    nationality_jp2: "ブルンジ",
    group: "",
  },
  {
    nationality: "Vietnam",
    nationality_jp: "ベトナム",
    nationality_jp2: "ベトナム",
    group: "",
  },
  {
    nationality: "Benin",
    nationality_jp: "ベナン",
    nationality_jp2: "ベナン",
    group: "",
  },
  {
    nationality: "Venezuela",
    nationality_jp: "ベネズエラ",
    nationality_jp2: "ベネズエラ",
    group: "",
  },
  {
    nationality: "Belarus",
    nationality_jp: "ベラルーシ",
    nationality_jp2: "ベラルーシ",
    group: "",
  },
  {
    nationality: "Belize",
    nationality_jp: "ベリーズ",
    nationality_jp2: "ベリーズ",
    group: "",
  },
  {
    nationality: "Peru",
    nationality_jp: "ペルー",
    nationality_jp2: "ペルー",
    group: "",
  },
  {
    nationality: "Belgium",
    nationality_jp: "ベルギー",
    nationality_jp2: "ベルギー",
    group: "",
  },
  {
    nationality: "Poland",
    nationality_jp: "ポーランド",
    nationality_jp2: "ポーランド",
    group: "",
  },
  {
    nationality: "Bosnia-Herzegovina",
    nationality_jp: "ボスニア・ヘルツェゴビナ",
    nationality_jp2: "ボスニア・ヘルツェゴビナ",
    group: "",
  },
  {
    nationality: "Botswana",
    nationality_jp: "ボツワナ",
    nationality_jp2: "ボツワナ",
    group: "",
  },
  {
    nationality: "Bolivia",
    nationality_jp: "ボリビア",
    nationality_jp2: "ボリビア",
    group: "",
  },
  {
    nationality: "Portugal",
    nationality_jp: "ポルトガル",
    nationality_jp2: "ポルトガル",
    group: "",
  },
  {
    nationality: "Honduras",
    nationality_jp: "ホンジュラス",
    nationality_jp2: "ホンジュラス",
    group: "",
  },
  {
    nationality: "Marshall",
    nationality_jp: "マーシャル",
    nationality_jp2: "マーシャル",
    group: "",
  },
  {
    nationality: "The former Yugoslav Republic Of Macedonia",
    nationality_jp: "マケドニア旧ユーゴスラビア共和国",
    nationality_jp2: "マケドニアキュウユーゴスラビアキョウワコク",
    group: "",
  },
  {
    nationality: "Madagascar",
    nationality_jp: "マダガスカル",
    nationality_jp2: "マダガスカル",
    group: "",
  },
  {
    nationality: "Malawi",
    nationality_jp: "マラウイ",
    nationality_jp2: "マラウイ",
    group: "",
  },
  {
    nationality: "Mali",
    nationality_jp: "マリ",
    nationality_jp2: "マリ",
    group: "",
  },
  {
    nationality: "Malta",
    nationality_jp: "マルタ",
    nationality_jp2: "マルタ",
    group: "",
  },
  {
    nationality: "Malaysia",
    nationality_jp: "マレーシア",
    nationality_jp2: "マレーシア",
    group: "",
  },
  {
    nationality: "Micronesia",
    nationality_jp: "ミクロネシア",
    nationality_jp2: "ミクロネシア",
    group: "",
  },
  {
    nationality: "Republic Of South Africa",
    nationality_jp: "南アフリカ共和国",
    nationality_jp2: "ミナミアフリカキョウワコク",
    group: "",
  },
  {
    nationality: "Myanmar",
    nationality_jp: "ミャンマー",
    nationality_jp2: "ミャンマー",
    group: "",
  },
  {
    nationality: "Stateless",
    nationality_jp: "無国籍",
    nationality_jp2: "ムコクセキ",
    group: "",
  },
  {
    nationality: "Mexico",
    nationality_jp: "メキシコ",
    nationality_jp2: "メキシコ",
    group: "",
  },
  {
    nationality: "Mauritius",
    nationality_jp: "モーリシャス",
    nationality_jp2: "モーリシャス",
    group: "",
  },
  {
    nationality: "Mauritania",
    nationality_jp: "モーリタニア",
    nationality_jp2: "モーリタニア",
    group: "",
  },
  {
    nationality: "Mozambique",
    nationality_jp: "モザンビーク",
    nationality_jp2: "モザンビーク",
    group: "",
  },
  {
    nationality: "Monaco",
    nationality_jp: "モナコ",
    nationality_jp2: "モナコ",
    group: "",
  },
  {
    nationality: "Maldives",
    nationality_jp: "モルディブ",
    nationality_jp2: "モルディブ",
    group: "",
  },
  {
    nationality: "Moldova",
    nationality_jp: "モルドバ",
    nationality_jp2: "モルドバ",
    group: "",
  },
  {
    nationality: "Morocco",
    nationality_jp: "モロッコ",
    nationality_jp2: "モロッコ",
    group: "",
  },
  {
    nationality: "Mongolia",
    nationality_jp: "モンゴル",
    nationality_jp2: "モンゴル",
    group: "",
  },
  {
    nationality: "Montenegro",
    nationality_jp: "モンテネグロ",
    nationality_jp2: "モンテネグロ",
    group: "",
  },
  {
    nationality: "Jordan",
    nationality_jp: "ヨルダン",
    nationality_jp2: "ヨルダン",
    group: "",
  },
  {
    nationality: "Laos",
    nationality_jp: "ラオス",
    nationality_jp2: "ラオス",
    group: "",
  },
  {
    nationality: "Latvia",
    nationality_jp: "ラトビア",
    nationality_jp2: "ラトビア",
    group: "",
  },
  {
    nationality: "Lithuania",
    nationality_jp: "リトアニア",
    nationality_jp2: "リトアニア",
    group: "",
  },
  {
    nationality: "Libya",
    nationality_jp: "リビア",
    nationality_jp2: "リビア",
    group: "",
  },
  {
    nationality: "Liechtenstein",
    nationality_jp: "リヒテンシュタイン",
    nationality_jp2: "リヒテンシュタイン",
    group: "",
  },
  {
    nationality: "Liberia",
    nationality_jp: "リベリア",
    nationality_jp2: "リベリア",
    group: "",
  },
  {
    nationality: "Romania",
    nationality_jp: "ルーマニア",
    nationality_jp2: "ルーマニア",
    group: "",
  },
  {
    nationality: "Luxembourg",
    nationality_jp: "ルクセンブルク",
    nationality_jp2: "ルクセンブルク",
    group: "",
  },
  {
    nationality: "Rwanda",
    nationality_jp: "ルワンダ",
    nationality_jp2: "ルワンダ",
    group: "",
  },
  {
    nationality: "Lesotho",
    nationality_jp: "レソト",
    nationality_jp2: "レソト",
    group: "",
  },
  {
    nationality: "Lebanon",
    nationality_jp: "レバノン",
    nationality_jp2: "レバノン",
    group: "",
  },
  {
    nationality: "Russia",
    nationality_jp: "ロシア",
    nationality_jp2: "ロシア",
    group: "",
  },
];

export const PREFECTURE_LIST = [
  {
    id: 1,
    name: "北海道",
  },
  {
    id: 2,
    name: "青森県",
  },
  {
    id: 3,
    name: "岩手県",
  },
  {
    id: 4,
    name: "宮城県",
  },
  {
    id: 5,
    name: "秋田県",
  },
  {
    id: 6,
    name: "山形県",
  },
  {
    id: 7,
    name: "福島県",
  },
  {
    id: 8,
    name: "茨城県",
  },
  {
    id: 9,
    name: "栃木県",
  },
  {
    id: 10,
    name: "群馬県",
  },
  {
    id: 11,
    name: "埼玉県",
  },
  {
    id: 12,
    name: "千葉県",
  },
  {
    id: 13,
    name: "東京都",
  },
  {
    id: 14,
    name: "神奈川県",
  },
  {
    id: 15,
    name: "新潟県",
  },
  {
    id: 16,
    name: "富山県",
  },
  {
    id: 17,
    name: "石川県",
  },
  {
    id: 18,
    name: "福井県",
  },
  {
    id: 19,
    name: "山梨県",
  },
  {
    id: 20,
    name: "長野県",
  },
  {
    id: 21,
    name: "岐阜県",
  },
  {
    id: 22,
    name: "静岡県",
  },
  {
    id: 23,
    name: "愛知県",
  },
  {
    id: 24,
    name: "三重県",
  },
  {
    id: 25,
    name: "滋賀県",
  },
  {
    id: 26,
    name: "京都府",
  },
  {
    id: 27,
    name: "大阪府",
  },
  {
    id: 28,
    name: "兵庫県",
  },
  {
    id: 29,
    name: "奈良県",
  },
  {
    id: 30,
    name: "和歌山県",
  },
  {
    id: 31,
    name: "鳥取県",
  },
  {
    id: 32,
    name: "島根県",
  },
  {
    id: 33,
    name: "岡山県",
  },
  {
    id: 34,
    name: "広島県",
  },
  {
    id: 35,
    name: "山口県",
  },
  {
    id: 36,
    name: "徳島県",
  },
  {
    id: 37,
    name: "香川県",
  },
  {
    id: 38,
    name: "愛媛県",
  },
  {
    id: 39,
    name: "高知県",
  },
  {
    id: 40,
    name: "福岡県",
  },
  {
    id: 41,
    name: "佐賀県",
  },
  {
    id: 42,
    name: "長崎県",
  },
  {
    id: 43,
    name: "熊本県",
  },
  {
    id: 44,
    name: "大分県",
  },
  {
    id: 45,
    name: "宮崎県",
  },
  {
    id: 46,
    name: "鹿児島県",
  },
  {
    id: 47,
    name: "沖縄県",
  },
];

export const PUBLIC_LAYOUT = "default";

export const LIST_MENU = [
  {
    name: "dashboard",
    title: "ダッシュボード",
    url: "/",
    icon: "mdi-view-dashboard",
    level: 1,
    child: false,
  },
  {
    name: "staff",
    title: "従業員管理",
    url: "",
    icon: "mdi-account-multiple",
    level: 1,
    child: true,
    submenu: [
      {
        name: "staff_list",
        title: "全従業員リスト",
        url: "/staff/list",
        icon: "mdi-ticket",
        level: 2,
        parent: "staff",
        child: false,
      },
      {
        name: "input_status",
        title: "入力状況管理",
        url: "/staff/input/status",
        icon: "mdi-ticket",
        level: 2,
        parent: "staff",
        child: false,
      },
      {
        name: "update_target",
        title: "更新対象者リスト",
        url: "/staff/update/target",
        icon: "mdi-ticket",
        level: 2,
        parent: "staff",
        child: false,
      },
      {
        name: "zaimane",
        title: "アプリ読取リスト",
        url: "/staff/zaimane",
        icon: "mdi-ticket",
        level: 2,
        parent: "staff",
        child: false,
        isApplicationGuestUse: true,
      },
      {
        name: "approval",
        title: "承認待ちリスト",
        url: "/staff/approval",
        icon: "mdi-ticket",
        level: 2,
        parent: "staff",
        child: false,
        isRequesterOrApprover: true,
      },
    ],
  },
  {
    name: "hellowork",
    title: "雇用状況届出書出力",
    url: "/hellowork/electronic",
    icon: "mdi-file",
    level: 1,
    child: false,
  },
  {
    name: "company",
    title: "企業管理",
    url: "",
    icon: "mdi-office-building",
    level: 1,
    child: true,
    submenu: [
      {
        name: "shop_list",
        title: "店舗リスト",
        url: "/company/shop/list",
        icon: "mdi-ticket",
        level: 2,
        parent: "company",
        child: false,
      },
      {
        name: "office_list",
        title: "事業所リスト",
        url: "/company/office/list",
        icon: "mdi-ticket",
        level: 2,
        parent: "company",
        child: false,
      },
    ],
  },
  {
    name: "admin",
    title: "管理者機能",
    url: "",
    icon: "mdi-cog",
    level: 1,
    child: true,
    role: [AUTHORITY.MASTER],
    submenu: [
      {
        name: "list",
        title: "ユーザー管理",
        url: "/admin/list",
        icon: "mdi-ticket",
        level: 2,
        parent: "admin",
        child: false,
        role: [AUTHORITY.MASTER],
      },
      {
        name: "email_setting",
        title: "通知メール設定",
        url: "/admin/mail/setting",
        level: 2,
        parent: "admin",
        child: false,
        role: [AUTHORITY.MASTER],
      },
      {
        name: "ip_address",
        title: "アクセス制限",
        url: "/admin/ip-address",
        icon: "mdi-ticket",
        level: 2,
        parent: "admin",
        child: false,
        role: [AUTHORITY.MASTER],
      },
      {
        name: "feature_setting",
        title: "利用機能設定",
        url: "/admin/feature/setting",
        icon: "mdi-ticket",
        level: 2,
        parent: "admin",
        child: false,
        role: [AUTHORITY.MASTER],
      },
      {
        name: "customized",
        title: "回収必須項目設定",
        url: "/admin/customized",
        icon: "mdi-ticket",
        level: 2,
        parent: "admin",
        child: false,
        role: [AUTHORITY.MASTER],
      },
      {
        name: "paymentList",
        title: "請求詳細",
        url: "/admin/payment/list",
        icon: "mdi-ticket",
        level: 2,
        parent: "admin",
        child: false,
        role: [AUTHORITY.MASTER],
      },
    ],
  },
];

export const DATE_OF_WEEK_LIST = [
  {
    name: "月",
    value: 0,
  },
  {
    name: "火",
    value: 1,
  },
  {
    name: "水",
    value: 2,
  },
  {
    name: "木",
    value: 3,
  },
  {
    name: "金",
    value: 4,
  },
  {
    name: "土",
    value: 5,
  },
  {
    name: "日",
    value: 6,
  },
];

export const BUTTON_COLOR = {
  CANCEL: "#f50057",
  SET: "#1565C0",
  OK: "#209526",
};

export const NOTICE_TYPE_COLOR = {
  INFO: "#209526",
  UPDATE: "#1565C0",
  BUG: "#f50057",
  OTHER: "#979FAE",
};

export const COLOR = {
  DANGER: "#f50057",
  GOLD: "#f5b629",
  SUCCESS_SEA: "#209526",
};

export const GENDER_LIST = [
  { name: "男", value: "Male" },
  { name: "女", value: "Female" },
];

export const VISA_STATUS_LIST = [
  { name: "失効", value: "Invalid" },
  { name: "失効していない", value: "Valid" },
  { name: "未確認", value: "Not yet confirmed" },
  { name: "確認対象外", value: "No need to confirm" },
];

export const WORKING_PERMISSION_LIST = [
  { name: "あり", value: "Have Permission" },
  { name: "なし", value: "No Permission" },
  { name: "申請中", value: "Applying For Permission" },
  { name: "確認対象外", value: "Not available" },
];

export const JOB_QUALIFICATION_LIST = [
  { name: "確認済み（問題なし）", value: "No problem" },
  { name: "確認済み（単純労働者）", value: "Unskilled laborer" },
  { name: "未確認", value: "Unconfirmed" },
];

export const VISA_TYPE_WORK_QUALIFICATION_CONFIRM_LIST = [
  { name: "特別永住者", value: "Special Permanent Resident" },
  { name: "永住者", value: "Permanent" },
  {
    name: "日本人の配偶者等",
    value: "Spouse or Child of Japanese National",
  },
  {
    name: "永住者の配偶者等",
    value: "Spouse or Child of Permanent Resident",
  },
  { name: "定住者", value: "Long Term" },
  { name: "留学", value: "Student" },
  { name: "文化活動", value: "Cultural Activities" },
  { name: "研修", value: "Trainee" },
  { name: "短期滞在", value: "Temporary Visitor" },
  { name: "家族滞在", value: "Dependent" },
];

export const CARD_CHECK_LIST = [
  { name: "実施済み（偽造の可能性あり）", value: true },
  { name: "実施済み（問題なし）", value: false },
  { name: "偽造チェック未実施", value: null },
];

export const REQUEST_TEXT_LIST = [
  {
    name:
      "写真がぼやけて文字が読めませんでした。\n" +
      "はっきり見えるように写真を撮って提出してください。",
    value: "request_common1",
    types:
      "residenceCardFront,residenceCardBack,workDesignation,passport,studentCard,certificateLongVacation,カスタマイズのname,ResidenceCardReject",
  },
  {
    name:
      "写真が反射して文字が読めませんでした。\n" +
      "はっきり見えるように写真を撮って提出してください。",
    value: "request_common2",
    types:
      "residenceCardFront,residenceCardBack,workDesignation,passport,studentCard,certificateLongVacation,カスタマイズのname,ResidenceCardReject",
  },
  {
    name:
      "写真が暗くて文字が読めませんでした。\n" +
      "はっきり見えるように写真を撮って提出してください。",
    value: "request_common3",
    types:
      "residenceCardFront,residenceCardBack,workDesignation,passport,studentCard,certificateLongVacation,カスタマイズのname,ResidenceCardReject",
  },
  {
    name:
      "写真が見切れて文字が読めませんでした。\n" +
      "全体が見えるように写真を撮って提出してください。",
    value: "request_common4",
    types:
      "residenceCardFront,residenceCardBack,workDesignation,passport,studentCard,certificateLongVacation,カスタマイズのname,ResidenceCardReject",
  },
  {
    name:
      "指が映っていて文字が読めませんでした。\n" +
      "全体が見えるように写真を撮って提出してください。",
    value: "request_common5",
    types:
      "residenceCardFront,residenceCardBack,workDesignation,passport,studentCard,certificateLongVacation,カスタマイズのname,ResidenceCardReject",
  },
  {
    name:
      "コピーの写真が提出されました。\n" +
      "在留カード原本の表面の写真を撮って提出してください。",
    value: "request_card_front1",
    types: "residenceCardFront,ResidenceCardReject",
  },
  {
    name:
      "写真が在留カードの表面ではありません。\n" +
      "在留カードの表面の写真を撮って提出してください。",
    value: "request_card_front2",
    types: "residenceCardFront",
  },
  {
    name:
      "在留カード表面の提出が必要です。\n" +
      "在留カード表面の写真を撮って提出してください。",
    value: "request_card_front3",
    types: "residenceCardFront",
  },
  {
    name:
      "資格外活動許可のスタンプが確認できませんでした。\n" +
      "最新の在留カードの裏面の写真を撮って提出してください。",
    value: "request_card_back1",
    types: "residenceCardBack,ResidenceCardReject",
  },
  {
    name:
      "在留カードの裏面に住所が書かれていません。\n" +
      "最新の在留カードの裏面の写真を撮って提出してください。",
    value: "request_card_back2",
    types: "residenceCardBack,ResidenceCardReject",
  },
  {
    name:
      "住所が変更がありました。\n" +
      "最新の在留カードの裏面の写真を撮って提出してください。",
    value: "request_card_back3",
    types: "residenceCardBack,ResidenceCardReject",
  },
  {
    name:
      "コピーの写真が提出されました。\n" +
      "在留カード原本の裏面の写真を撮って提出してください。",
    value: "request_card_back4",
    types: "residenceCardBack,ResidenceCardReject",
  },
  {
    name:
      "写真が在留カードの裏面ではありません。\n" +
      "在留カードの裏面の写真を撮って提出してください。",
    value: "request_card_back5",
    types: "residenceCardBack,ResidenceCardReject",
  },
  {
    name:
      "在留カード裏面の提出が必要です。\n" +
      "在留カード裏面の写真を撮って提出してください。",
    value: "request_card_back6",
    types: "residenceCardBack,ResidenceCardReject",
  },
  {
    name:
      "指定書の提出が必要な在留資格です。\n" +
      "パスポートに付いている指定書の写真を撮って提出してください。",
    value: "request_designation1",
    types: "workDesignation,ResidenceCardReject",
  },
  {
    name:
      "指定書に【CANCELLED】のスタンプが押されています。\n" +
      "最新の指定書の写真を撮って提出してください。",
    value: "request_designation2",
    types: "workDesignation,ResidenceCardReject",
  },
  {
    name:
      "写真が指定書ではありません。\n" +
      "パスポートに付いている指定書の写真を撮って提出してください。",
    value: "request_designation3",
    types: "workDesignation,ResidenceCardReject",
  },
  {
    name:
      "指定書の提出が必要です。\n" +
      "パスポートに付いている指定書の写真を撮って提出してください。",
    value: "request_designation4",
    types: "workDesignation,ResidenceCardReject",
  },
  {
    name:
      "資格外活動許可の詳細の確認が必要です。\n" +
      "資格外活動許可書の写真を撮って提出してください。",
    value: "request_designation5",
    types: "workDesignation,ResidenceCardReject",
  },
  {
    name:
      "写真がパスポートではありません。\n" +
      "パスポートの写真を撮って提出してください。",
    value: "request_passport1",
    types: "passport",
  },
  {
    name:
      "パスポートの提出が必要です。\n" +
      "パスポートの写真を撮って提出してください。",
    value: "request_passport2",
    types: "passport",
  },
  {
    name:
      "写真が学生証ではありません。\n" +
      "学生証の写真を撮って提出してください。",
    value: "request_student_card1",
    types: "studentCard",
  },
  {
    name:
      "学生証の提出が必要です。\n" + "学生証の写真を撮って提出してください。",
    value: "request_student_card2",
    types: "studentCard",
  },
  {
    name:
      "写真が長期休暇の証明書ではありません。\n" +
      "長期休暇の証明書の写真を撮って提出してください。",
    value: "request_certificate_of_leave1",
    types: "certificateLongVacation",
  },
  {
    name:
      "長期休暇の証明書の提出が必要です。\n" +
      "長期休暇の証明書の写真を撮って提出してください。",
    value: "request_certificate_of_leave2",
    types: "certificateLongVacation",
  },
  {
    name:
      "写真が#customized_item#ではありません。\n" +
      "#customized_item#の写真を撮って提出してください。",
    value: "request_customized1",
    types: "カスタマイズのname",
  },
  {
    name:
      "#customized_item#の提出が必要です。\n" +
      "#customized_item#の写真を撮って提出してください。",
    value: "request_customized2",
    types: "カスタマイズのname",
  },
  {
    name: "その他",
    value: "Other",
    types:
      "residenceCardFront,residenceCardBack,workDesignation,passport,studentCard,certificateLongVacation,カスタマイズのname,Updating,Applying,ResidenceCardReject",
  },
];

export const REQUEST_TEXT_OLD_LIST = [
  {
    name: "画像(がぞう)がぼやけて不鮮明(ふせんめい)なため、はっきり映(うつ)る様に写真(しゃしん)を撮(と)って提出(ていしゅつ)してください。",
    value:
      "Please re-upload in order to be able to see clearly since image you uploaded is blurred.",
    types: "ResidenceCardReject",
  },
  {
    name: "反射（はんしゃ）して不鮮明（ふせんめい）なため、はっきり映(うつ)る様に写真(しゃしん)を撮(と)ってもう一回(いっかい)提出(ていしゅつ)してください。",
    value:
      "Please re-upload in order to be able to see clearly since image you uploaded is reflected.",
    types: "ResidenceCardReject",
  },
  {
    name: "在留(ざいりゅう)カードが見切(みき)れてしまっているため、全体(ぜんたい)が映(うつ)る様に写真(しゃしん)を撮(と)ってもう一回(いっかい)提出(ていしゅつ)してください。",
    value:
      "Please re-upload in order to be able to see entirely since Residence Card you uploaded is cut off.",
    types: "ResidenceCardReject",
  },
  {
    name: "在留(ざいりゅう)カードのオモテの写真(しゃしん)を撮(と)って提出(ていしゅつ)してください。",
    value: "Please re-upload the Residence Card (Front).",
    types: "ResidenceCardReject",
  },
  {
    name: "在留(ざいりゅう)カードのウラの写真(しゃしん)を撮(と)って提出(ていしゅつ)してください。",
    value: "Please re-upload the Residence Card (Back).",
    types: "ResidenceCardReject",
  },
  {
    name: "パスポートについている、指定書(していしょ)の写真(しゃしん)を撮(と)って提出(ていしゅつ)してください。",
    value: "Please upload an attached Designation paper on your passport.",
    types: "ResidenceCardReject",
  },
];

export const PAYMENT_TYPE = {
  FLAT: "flat",
  UNIT: "unit",
};

export const PAYMENT_PATTERN = {
  PAY_USE: "pay use",
  MIN_UNIT: "min unit",
  FLAT_RATE: "flat rate",
};

export const RESIDENCE_STATUS_JUDGE_PATTERN = {
  DEFAULT: 1,
  STATUS: 2,
  NO_WORKING: 3,
  SPECIAL: 4,
};

export const EMAIL_VERIFY = {
  DONE: "done",
  WRONG_URL: "wrong url",
  OLD_URL: "old url",
  DUP_MAIL_ADDRESS: "dup mail address",
  SEVER_ERROR: "server error",
};

export const EMAIL_VERIFY_ERROR = {
  TOKEN_NOT_FOUND: "トークンが間違っています",
  URL_EXPIRATION_DATE_ERROR: "URLの有効期限が切れました",
  EMAIL_DUPLICATED: "既に使われてるメールアドレスです",
  WAITING_TRY: "時間を置いて、再度お試しください",
};

export const JUDGE_PROCESS = {
  RESULT: "result", // 画面⑥
  HAVE_WORK_DESIGNATION: "have_work_designation", // 画面⑤
  WORK_DESIGNATION: "work_designation", // 画面④
  NFC_EF: "nfc_ef", // 画面③
  NFC_UPDATE_APPLYING: "nfc_update_applying", // 画面②
};

export const WORK_DESIGNATION_TEXT_DICT = {
  1: "収入を伴う事業を運営する活動または報酬を受ける活動は除く",
  2: "・ワーキング・ホリデーに関する…<br />・本邦に在留し難民設定申請…",
  3: "28時間を上限として収入を伴う事業を運営する活動…",
  4: "「特定産業分野　外食業」と働く先の会社名",
  5: "「下記の期間との契約に基づいて、当該期間の常勤の職員として行う当該期間の行に従事する活動」と働く先の会社名<br /><br />もしくは<br /><br />技能実習の在留資格を持って…",
};

export const TERM_ACTION = {
  CONTRACTOR_SHOW_TERM: "contractor show term",
  SHOW_INQUIRY: "show inquiry",
  CONTRACTOR_PERIOD_PASSED: "contractor period passed",
  PERIOD_PASSED: "period passed",
};

export const PRIVACY_POLICY_ACTION = {
  CONTRACTOR_SHOW_PRIVACY: "contractor show privacy",
  SHOW_INQUIRY: "show inquiry",
  CONTRACTOR_PERIOD_PASSED: "contractor period passed",
  PERIOD_PASSED: "period passed",
};

export const POPUP_ACTION = {
  FIRST_LOGIN: "first login",
  FIRST_LOGIN_END_TRIAL: "first login end trial",
  FIRST_LOGIN_UNLIMITED: "first login unlimited",
  END_TRIAL_FIVE_DAY: "end trial five day",
};

export const EXPLAIN_ACTION = {
  TRIAL: "trial",
  TRIAL_CONTRACTOR: "trial contractor",
  TRIAL_END: "trial end",
  TRIAL_END_CONTRACTOR: "trial end contractor",
  ONGOING: "ongoing",
  ONGOING_CONTRACTOR: "ongoing contractor",
  REJECTED: "rejected",
  REJECTED_CONTRACTOR: "rejected contractor",
  DISAGREE_TERM_CONTRACTOR: "disagree term contractor",
  DISAGREE_TERM: "disagree term",
};

export const IP_ADDRESS_FORMAT =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const DEFAULT_TIMER_ALERT = 3000;

export const FORMAT_NUMBER = /(\d)(?=(\d{3})+(?!\d))/g;

export const FORMAT_EMAIL = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const MIN_LENGTH_PASSWORD = 8;

export const MAX_LENGTH_RESIDENCE_CARD_NUMBER = 12;

export const REGEX_UPPERCASE_LOWERCASE = /^(?=.*[a-z])(?=.*[A-Z])/;

export const REGEX_ONE_SPECIAL_CHAR =
  /^(?=.*[!#$%&'()*+,-./:;<=>?@[\]^_`{|}~）"])/;

export const REGEX_ONE_NUMBER = /^(?=.*[0-9])/;

export const FORMAT_RESIDENCE_CARD_NUMBER = /^[A-Z]{2}\d{8}[A-Z]{2}$/;

export const REGEX_FULLSIZE =
  /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;

// eslint-disable-next-line no-irregular-whitespace
export const REGEX_KATAKANA_FULLSIZE = /^[ァ-ヴ][ァ-ヴー・＝･=　]*$/;

export const FORMAT_DATE =
  /^(((19|20)\d{2})+年(0[1-9]|1[0-2])+月(0[1-9]|[12]\d|3[01])+(日)*)$/;

export const REGEX_DATE_NONE =
  /^(((19|20)\d{2})+(0[1-9]|1[0-2])+(0[1-9]|[12]\d|3[01]))$/;

export const REGEX_DATE_HYPHEN =
  /^(((19|20)\d{2})+-(0[1-9]|1[0-2])+-(0[1-9]|[12]\d|3[01]))$/;

export const REGEX_DATE_SLASH =
  /^(((19|20)\d{2})+\/(0[1-9]|1[0-2])+\/(0[1-9]|[12]\d|3[01]))$/;

export const REGEX_ALPHABET = /^[a-zA-Z ]*$/;

export const REGEX_UPPER_ALPHABET_SPACE = /^[A-Z ]+$/;

export const REGEX_ELEVEN_NUMBER = /^[0-9]{11}$/;

export const REGEX_ELEVEN_NUMBER_ALPHABET = /^[0-9a-zA-Z]{11}$/;

export const REGEX_ZIP_CODE = /^\d{7}$|^\d{3}-\d{4}$/;

export const MOBILE_LIST =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const STYLE_DATE_PICKER = {
  "--vdp-bg-color": "#ffffff",
  "--vdp-text-color": "#4a576e",
  "--vdp-box-shadow":
    "0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
  "--vdp-border-radius": "3px",
  "--vdp-heading-size": "2.5em",
  "--vdp-heading-weight": "bold",
  "--vdp-heading-hover-color": "#fbb1ca",
  "--vdp-arrow-color": "#4a576e",
  "--vdp-elem-color": "#4a576e",
  "--vdp-disabled-color": "#979fae",
  "--vdp-hover-color": "#4a576e",
  "--vdp-hover-bg-color": "#fbb1ca",
  "--vdp-selected-color": "#ffffff",
  "--vdp-selected-bg-color": "#f50057",
  "--vdp-elem-font-size": "0.8em",
  "--vdp-elem-border-radius": "3px",
  "--vdp-divider-color": "#d5d9e0",
};
