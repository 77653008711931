<template>
  <div class="form-login">
    <form @submit.prevent="submit">
      <h1 class="title text-center mb-8">ビザマネにログイン</h1>

      <div class="error" v-if="messageErrorLogin">
        <p v-html="getMessageErrorLogin()"></p>
      </div>

      <div
        class="success fw-700"
        v-if="this.verifyMessageType === emailVerify.DONE"
      >
        <p>メールアドレスの認証が完了しました。</p>
      </div>

      <div
        class="error fw-700"
        v-if="this.verifyMessageType === emailVerify.WRONG_URL"
      >
        <p>
          アクセスしたURLが古いため認証に失敗しました。<br />
          最新の認証メールに記載のURLから再度お試しください。
        </p>
      </div>

      <div
        class="error fw-700"
        v-if="this.verifyMessageType === emailVerify.OLD_URL"
      >
        <p>
          アクセスしたURLの有効期限が切れています。
          <br />ログイン後、アカウント設定ページから認証メールの再送をしてください。
        </p>
      </div>

      <div
        class="error fw-700"
        v-if="this.verifyMessageType === emailVerify.DUP_MAIL_ADDRESS"
      >
        <p>
          このメールアドレスは既に使われています。<br />ログイン後、アカウント設定ページからメールアドレスの変更をお願いします。
        </p>
      </div>

      <div
        class="error fw-700"
        v-if="this.verifyMessageType === emailVerify.SEVER_ERROR"
      >
        <p>時間を置いて、再度お試しください。</p>
      </div>

      <div class="email text-center mb-5">
        <InputComponent
          type="email"
          :value="formLogin.email"
          placeholder="メールアドレス"
          @handleChange="onChangeEmail"
        />
      </div>

      <div class="password text-center mb-5">
        <InputComponent
          type="password"
          :value="formLogin.password"
          placeholder="パスワード"
          @handleChange="onChangePassword"
        />
      </div>

      <div class="text-center mt-10">
        <button
          :class="['btn fs-16 border-8', { disabled: disabledBtnLogin }]"
          :disabled="disabledBtnLogin"
          @click="
            login({
              email: formLogin.email,
              password: formLogin.password,
            })
          "
        >
          ログイン
        </button>
      </div>
    </form>
  </div>
  <div class="link fs-14">
    <div class="pb-4">
      <router-link :to="{ name: 'resetPasswordMail' }">
        パスワードをお忘れの方はこちら
      </router-link>
    </div>
    <div>
      <router-link :to="{ name: 'registerCompany' }">
        新しく企業アカウントを作成する
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InputComponent from "@/components/InputComponent.vue";
import AdminService from "@/services/admin.service.js";
import { EMAIL_VERIFY, EMAIL_VERIFY_ERROR } from "@/common/constants.js";

export default {
  name: "Login",

  components: {
    InputComponent,
  },

  data() {
    return {
      formLogin: {
        email: "",
        password: "",
      },
      token: this.$route.query.token,
      verifyMessageType: null,
      emailVerify: EMAIL_VERIFY,
    };
  },

  created() {
    if (this.token) {
      this.verifyEmailOnly(this.token);
    }
  },

  computed: {
    ...mapState(["messageErrorLogin", "isPasswordIncorrect"]),

    disabledBtnLogin: function () {
      if (this.formLogin.email !== "" && this.formLogin.password !== "") {
        return false;
      }
      return true;
    },
  },

  watch: {
    isPasswordIncorrect: function () {
      if (this.isPasswordIncorrect) {
        this.formLogin.password = "";
      }
    },
  },

  methods: {
    ...mapActions(["login"]),

    onChangeEmail(email) {
      this.formLogin.email = email;
    },

    onChangePassword(pass) {
      this.formLogin.password = pass;
    },

    getMessageErrorLogin() {
      return this.messageErrorLogin.replaceAll("\n", "<br />");
    },

    // call api send verify email admin
    verifyEmailOnly(token) {
      AdminService.verifyEmailOnly(token)
        .then(() => {
          this.verifyMessageType = EMAIL_VERIFY.DONE;
        })
        .catch((err) => {
          if (err.message.content === EMAIL_VERIFY_ERROR.TOKEN_NOT_FOUND) {
            this.verifyMessageType = EMAIL_VERIFY.WRONG_URL;
          } else if (
            err.message.content === EMAIL_VERIFY_ERROR.URL_EXPIRATION_DATE_ERROR
          ) {
            this.verifyMessageType = EMAIL_VERIFY.OLD_URL;
          } else if (
            err.message.content === EMAIL_VERIFY_ERROR.EMAIL_DUPLICATED
          ) {
            this.verifyMessageType = EMAIL_VERIFY.DUP_MAIL_ADDRESS;
          } else if (err.message.content === EMAIL_VERIFY_ERROR.WAITING_TRY) {
            this.verifyMessageType = EMAIL_VERIFY.SEVER_ERROR;
          }
        });
    },
  },
};
</script>
